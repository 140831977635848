import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators} from '@angular/forms';
import { accountModel, authModel } from 'src/app/data/models';
import { DbManager } from 'src/app/classes/dbm';
import { Location } from '@angular/common';
import { RequestService } from '../../../services/request-service';
import { GlobalsService } from '../../../services/globals.service';
declare var $ :any;

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit 
{
  account:any;
  auth:any;
  url:string;
  accountForm:FormGroup = new FormGroup({
    firstname: new FormControl('', Validators.required),
    lastname: new FormControl('', Validators.required),
    middlename: new FormControl('', Validators.required),
    dateofbirth: new FormControl('', Validators.required),
    sex: new FormControl('', Validators.required),
    status: new FormControl('', Validators.required),
    mobile: new FormControl('', Validators.required),
    image: new FormControl('', Validators.required),
    authid: new FormControl('', Validators.required),
    address: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.pattern(".+\@.+\...+")]),
    id: new FormControl('', [Validators.required, Validators.pattern(".+\@.+\...+")]),
  });
  db: any;
  authid: string;
  username: string;

  constructor(
    public api: RequestService, 
    public gVars: GlobalsService, 
    public location: Location
  ) {
    this.db = new DbManager(this.api, this.gVars, this.location);
  }

  ngOnInit() 
  {
    this.account = JSON.parse(sessionStorage.getItem("userdata"));
  }

  addAccountInformation = (form: any) =>
  {
    let acctFormData = new FormData();
    let authFormData = new FormData();
    form.image = (this.gVars.previewImg == null) ? form.image : this.gVars.previewImg ;
    for(let key in form) {
      if(key == "access" || key == "username") {
        authFormData.append(key, form[key]);
      }else {
        acctFormData.append(key, form[key]);
      }
    }
    this.db.createData("account/register", authFormData, (res: any) => {
      if(res.response == "inserted"){
      }
    })
  }

  updateUser = (form:accountModel) =>
  {
    delete form['id'];
    form.image = (this.gVars.previewImg == null) ? form.image : this.gVars.previewImg ;
    this.db.updateData("account/authid/" + this.authid, form, (res: any) => {
    })
  }

  deleteUser = (userid: string) => 
  {
    this.db.deleteData("account/authid/",  userid, (res: any) => {
      if(res.response != "Not deleted")
      {
      }
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators} from '@angular/forms';
import { GlobalsService } from '../../../services/globals.service';
import { RequestService } from '../../../services/request-service';
import { Router } from '@angular/router';
import { DbManager } from 'src/app/classes/dbm';
import { Location } from '@angular/common';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit 
{
  loginForm: FormGroup = new FormGroup({
    email: new FormControl('', [ Validators.required, Validators.minLength(5) ]),
  });
  error:string;
  db: any;
  constructor(
    public api: RequestService, 
    public gVars: GlobalsService, 
    location: Location,
    public router: Router
  ) {
    this.db = new DbManager(api, gVars, location)
  }

  ngOnInit() 
  {
    // this.gVars.loginUI();
  }

  loginFunction = (credentails: any) =>
  {
    this.gVars.preloader('show');
    this.db.readAllData("accounts/access/client/email/" + credentails.email, (response: any) => {
      if(response && response.length > 0 && response != "No content" && response[0].access == "client") {
        let formData = new FormData;
        formData.append("email", response[0].email);
        formData.append("username", response[0].username);
        formData.append("authid", response[0].authid);
        formData.append("access", response[0].access);
        this.db.createData(
          "accounts/generateaccess", 
          formData, (resp) => {
            this.gVars.preloader("hide");
            this.api.jwt = resp.response.jwt;
            sessionStorage.setItem("token", resp.response.jwt);
            sessionStorage.setItem("userdata", JSON.stringify(resp.response.data));
            this.router.navigateByUrl("/overview");
          })
      }else if(response == "No content"){
        this.error = "Client information not found";
      }else if(response[0].access != "client"){
        this.error = "You cannot access this realm. Clients only";
      }
      setTimeout(() => {
        this.loginForm.patchValue({email: null});
        this.error = null;
        this.gVars.preloader('hide');
      }, 5000)
    })
  }

}

import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { GlobalsService } from './services/globals.service';
import { RequestService } from './services/request-service';
import { Router, ActivatedRoute } from '@angular/router';

declare var $ :any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent 
{
  constructor(
    public gVars: GlobalsService, 
    private api: RequestService,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute
    )
  {
    gVars.checkAccess();
    this.gVars.loader = $(".page-loader");
    sessionStorage.setItem('url', this.location.path());
  }
}

import { Component} from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
declare var $ :any;

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent
{
  private url:string = null;
  constructor(private location: Location, private router: Router) 
  { 
    this.url = this.location.path();
    this.openNav();
  }

  openNav(){
    if(this.url == "/Posts" || this.url == "/Posts/Comments" || this.url == "/Posts/Category")
    {
      console.log(this.url);
      $('.events.navigation__sub').addClass('navigation__sub--toggled');
      $('ul#posts').css('display', "block");
    }
  }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './components/error/error.component';

import { AuthComponent } from './pages/accounts/auth/auth.component';
import { UsersComponent } from './pages/accounts/users/users.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { ReportsComponent } from './pages/projects/reports/reports.component';
import { OverviewComponent } from './pages/overview/overview.component';
import { PaymentsComponent } from './pages/payments/payments.component';
import { OrdersComponent } from './pages/payments/orders/orders.component';
import { AddComponent } from './pages/projects/add/add.component';
import { RegisterComponent } from './pages/accounts/register/register.component';

const routes: Routes = [
  // { path: '', component: DashboardComponent },
  { path: 'overview', component: OverviewComponent },
  // Projects links
  { path: 'projects', component: ProjectsComponent },
  { path: 'projects/add', component: AddComponent },
  { path: 'projects/reports/:id', component: ReportsComponent },
  // Payments links
  { path: 'payments', component: PaymentsComponent },
  { path: 'payments/orders', component: OrdersComponent },
  // Accounts links
  { path: 'profile', component: UsersComponent },
  { path: 'login', component: AuthComponent },
  { path: 'register', component: RegisterComponent },
  { path: '404', component: ErrorComponent},
  {path: '**', redirectTo: '404'}
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ],
  declarations: []
})

export class AppRoutingModule 
{ 
}

import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../services/request-service';
import { GlobalsService } from '../../services/globals.service';
import { DbManager } from 'src/app/classes/dbm';
import { Location } from '@angular/common';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {

  data: any = {
    "transactions": [],
    "projects": [],
  };
  username: string;
  db: any;
  constructor(
    api: RequestService, 
    gVars: GlobalsService, 
    location: Location
  ) {
    this.db = new DbManager(api, gVars, location);
    this.username = JSON.parse(sessionStorage.getItem("userdata"))['username'];
    console.log(this.data);
  }

  ngOnInit() {
    let modules = ["projects", "transactions"];
    for(let i = 0; i < modules.length; i++) {
      let user = (modules[i] == "projects") ? "/owner/" + this.username :  "/user/" + this.username;
      this.db.readAllData(
        modules[i] + user, 
        (resp) => {
          this.data[modules[i]] = [] = resp;
      })
    }
  }

  firstLetter(str: string)
  {
    return str.split('')[0];
  }

}

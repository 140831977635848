import { Component } from '@angular/core';
import { GlobalsService } from '../../../services/globals.service';
import { Router } from '@angular/router';
import { RequestService } from 'src/app/services/request-service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent
{
  title:string;
  user:any;
  constructor(
    public gVars: GlobalsService,
    public api: RequestService,
    public router: Router
  ) { 
    this.title = this.gVars.title;
    this.user = JSON.parse(sessionStorage.getItem("userdata"));
  }

  logout = () =>
  {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("userdata");
      this.api.checkForJwt();
      this.router.navigateByUrl("/login");
      // window.location.href = "/login";
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
// services
import { RequestService } from './services/request-service';
// componenets
import { NavComponent } from './components/template/nav/nav.component';
import { HeaderComponent } from './components/template/header/header.component';
import { ErrorComponent } from './components/error/error.component';
import { NoContentComponent } from './components/no-content/no-content.component';
import { LoadingComponent } from './components/loading/loading.component';
// pages
import { AuthComponent } from './pages/accounts/auth/auth.component';
import { UsersComponent } from './pages/accounts/users/users.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { ReportsComponent } from './pages/projects/reports/reports.component';
import { OverviewComponent } from './pages/overview/overview.component';
import { PaymentsComponent } from './pages/payments/payments.component';
import { RegisterComponent } from './pages/accounts/register/register.component';
import { OrdersComponent } from './pages/payments/orders/orders.component';
import { AddComponent } from './pages/projects/add/add.component';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    HeaderComponent,
    ErrorComponent,
    NoContentComponent,
    LoadingComponent,
    // Pages components
    AuthComponent,
    UsersComponent,
    ProjectsComponent,
    ReportsComponent,
    OverviewComponent,
    PaymentsComponent,
    RegisterComponent,
    OrdersComponent,
    AddComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule  
  ],
  providers: [
    RequestService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { RequestService } from '../services/request-service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
declare var $ :any;

@Injectable({
  providedIn: 'root'
})
export class GlobalsService 
{
  loader: any;
  galleryImages:any = null;
  previewImg:string = null;
  url:string;
  title:string;
  constructor(private api: RequestService, private router: Router, private location: Location) 
  {
    this.url = this.location.path();
  }

  getUrl = () :any =>
  {
    return this.location.path();
  }

  cutString = (text = null, len = null) =>
  {    
      text = $($.parseHTML(text)).text();
      var i = 0;
      var wordsToCut = len;
      var wordsArray = text.split(" ");
      if(wordsArray.length>wordsToCut){
          var strShort = "";
          for(i = 0; i < wordsToCut; i++){
              strShort += wordsArray[i] + " ";
          }   
          return strShort+"...";
      }else{
          return text;
      }
  }

  checkAccess = async () =>
  {
    if(!sessionStorage.getItem('userdata'))
    {
      if(this.location.path() != "/login" && this.location.path() != "/register" && this.location.path() != "/404")
      {
        this.router.navigateByUrl("/login");
      }
      // else if(this.location.path() == "/login" || this.location.path() == "/register"){
      //   this.router.navigateByUrl(this.location.path());
      // }
    }
    else if(sessionStorage.getItem('userdata'))
    {
      if(this.location.path() == "/login" || this.location.path() == "/register")
      {
        this.router.navigateByUrl("/overview");
      }
    }
  }
  
  // extra libarries functionality

  objectToArray = (obj = {}) =>
  {
    var array = [], tempObject;
    for (var key in obj) 
    {
        tempObject = obj[key];
        // if (obj[key] == typeo) {
        //     tempObject = this.objectToArray(obj[key]);
        // }
        array[key] = tempObject;
    }
    return array;
  }

  arrayToObject = (arr = []) =>
  {
      var rv = {};
      for (var i = 0; i < arr.length; ++i)
        rv[i] = arr[i];
      return rv;
  }

  datatable = (tableId = null) =>
  {
    $(tableId).DataTable({ autoWidth: !1, responsive: !0, lengthMenu: [[15, 30, 45, -1], ["15 Rows", "30 Rows", "45 Rows", "Everything"]], language: { searchPlaceholder: "Search for records..." }, dom: "Blfrtip", buttons: [{ extend: "excelHtml5", title: "Export Data" }, { extend: "csvHtml5", title: "Export Data" }, { extend: "print", title: "Material Admin" }], initComplete: function (a, b) { $(this).closest(".dataTables_wrapper").prepend('<div class="dataTables_buttons hidden-sm-down actions"><span class="actions__item zmdi zmdi-print" data-table-action="print" /><span class="actions__item zmdi zmdi-fullscreen" data-table-action="fullscreen" /><div class="dropdown actions__item"><i data-toggle="dropdown" class="zmdi zmdi-download" /><ul class="dropdown-menu dropdown-menu-right"><a href="" class="dropdown-item" data-table-action="excel">Excel (.xlsx)</a><a href="" class="dropdown-item" data-table-action="csv">CSV (.csv)</a></ul></div></div>'); } }), $(".dataTables_filter input[type=search]").focus(function () { $(this).closest(".dataTables_filter").addClass("dataTables_filter--toggled"); }), $(".dataTables_filter input[type=search]").blur(function () { $(this).closest(".dataTables_filter").removeClass("dataTables_filter--toggled"); }), $("body").on("click", "[data-table-action]", function (a) {
      a.preventDefault(); var b = $(this).data("table-action"); if ("excel" === b && $(this).closest(".dataTables_wrapper").find(".buttons-excel").trigger("click"), "csv" === b && $(this).closest(".dataTables_wrapper").find(".buttons-csv").trigger("click"), "print" === b && $(this).closest(".dataTables_wrapper").find(".buttons-print").trigger("click"), "fullscreen" === b) {
          var c = $(this).closest(".card");
          c.hasClass("card--fullscreen") ? (c.removeClass("card--fullscreen"), $("body").removeClass("data-table-toggled")) : (c.addClass("card--fullscreen"), $("body").addClass("data-table-toggled"));
      }
    });
  }

  datatableReload = (tableId = null) =>
  {
    $(tableId).DataTable().ajax.reload();
  }

  preloader = (status = ('show' || 'hide')) =>
  {
    (status != 'show')
      ? this.loader.hide()
      : this.loader.show();
  }


bNotify(type, title, msg)
{
    switch(type)
    {
        case "Error":
        {
            $.notify({
                title: title, 
                message: '<strong>Oops</strong> '+msg+'. Contact tech support', 
            },{
              type: 'danger', 
              progress: 20 
            });
            break;
        }
        case "Success":
        {
            $.notify({ 
                title: title,
                message: '<strong>Success</strong> '+msg+'.', 
            },{
              type: 'success', 
              progress: 20 
            });
            break;
        }
    }
}

  wysitextarea = () =>
  {
    $('textarea').wysihtml5();
  }

  loginUI = async () =>
  {
    $('body').addClass('login-content');
  }

  /// gallery/ image functions

  clickUpload = () =>
  {
    $('input#file').click();
  }

  uploadImg = () =>
  {
    let photo = $("input#file")[0].files[0] // get file from input
    let formData = new FormData();
    formData.append("file", photo); 
    this.api.postRequest(sessionStorage.getItem('gallery_module') + "/files", formData)
    .then( res => {
      if(res.messgae = "OK")
      {
        this.getGalleryFiles(sessionStorage.getItem('gallery_module'));
        $('#listBtn').click();
      }
      else{
        $('.modal button.close').click();
      }
    })
    .catch( err => {
      console.error(err);
      $('.modal button.close').click();
    });
  }

  saveImageUrl = (imgUrl = null) =>
  {
    this.previewImg = imgUrl;
    $('.modal button.close').click();
    $('img').removeClass('hidden');
  }

  getGalleryFiles = (route = null) => 
  {
    sessionStorage.setItem('gallery_module', route);
    this.preloader('show');
    this.api.getRequest( route + "/files")
    .then( res => {
      this.preloader('hide');
      if(res.message == "OK")
      {
        this.galleryImages = this.objectToArray(res.response);
        delete this.galleryImages["lenght"];
        delete this.galleryImages["_proto_"];
      }
    })
  }

  uploadGalleryFiles = () =>
  {
    let formData = new FormData();
    this.preloader('show');
    this.api.postRequest( 
      sessionStorage.getItem('gallery_module') + "/files",
      formData
    )
    .then( res => {
      this.preloader('hide');
      if(res.message == "OK")
      {
        this.galleryImages = res.output.response;
      }
    })
  }

  deleteImage = (image: string) =>
  {
    image = image.replace(this.api.url, '');
    this.api.deleteRequest(
      sessionStorage.getItem('gallery_module') + "/files/" + encodeURI(image))
    .then((res) => {
      if(res.response == "File deleted") {
        this.getGalleryFiles(sessionStorage.getItem('gallery_module'));
      }else{
        this.bNotify("Error", "Failed ", res.response);
      }
    })
    .catch();
  }
}

import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { RequestService } from '../../services/request-service';
import { GlobalsService } from '../../services/globals.service';
import { DbManager } from 'src/app/classes/dbm';
import { Location } from '@angular/common';
declare var $ :any;

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit, AfterViewInit {
@ViewChild('projectList') projectList
  projects: any = [];
  projectInfo: any;
  username: string;
  display: boolean;
  db: any;
  constructor(
    api: RequestService, 
    gVars: GlobalsService, 
    location: Location
  ) {
    this.db = new DbManager(api, gVars, location);
    this.username = JSON.parse(sessionStorage.getItem("userdata"))['username'];
  }

  ngOnInit() {
    this.getAllProjects()
  }
  
  ngAfterViewInit(){
    console.log(this.projectList.nativeElement.classList);
    
  }

  hideLists(type) {
    if(type == "show"){
      $('.messages__sidebar').hide();
      $('.messages__body').removeClass('col-md-5').addClass('col-md-8');
      $('button.zmdi-assignment-o').removeClass('hidden');
      $('button.zmdi-eye').addClass('hidden');
    }
    else if(type == "hide"){
      $('button.zmdi-assignment-o').addClass('hidden');
      $('button.zmdi-eye').removeClass('hidden');
      $('.messages__sidebar').show();
      $('.messages__body').addClass('col-md-5').removeClass('col-md-8');
    }
  }

  getProjectInfo = (project) => {
    this.projectList.nativeElement.classList.remove('col-md-12');
    this.projectList.nativeElement.classList.add('col-md-7')
    this.display = true;
     return this.projectInfo = project;
    // console.log(this.projectInfo);
  }


  deleteProject(projectid){
    console.log(projectid);
      this.db.deleteData('projects/projectid/', projectid,(res)=>{
        console.log(res);
        if(res.message == "OK"){
          this.getAllProjects();
        }

        this.getAllProjects();
      })
  }

  getAllProjects(){
    this.db.readAllData(
      "projects/owner/" + this.username, 
      (resp) => {
        this.projects = resp;
        setTimeout(() => {
          console.log(this.projects)
        }, 1500);
    })
  }
}

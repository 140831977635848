import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators} from '@angular/forms';
import { GlobalsService } from '../../../services/globals.service';
import { RequestService } from '../../../services/request-service';
import { DbManager } from 'src/app/classes/dbm';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
declare var $ :any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent {

  registerForm: FormGroup = new FormGroup({
    email: new FormControl('', [ Validators.required, Validators.minLength(5) ]),
    access: new FormControl('client', [ Validators.required, Validators.minLength(5) ]),
    username: new FormControl('', [ Validators.required, Validators.minLength(5) ]),
    firstname: new FormControl('', [ Validators.required, Validators.minLength(5) ]),
    lastname: new FormControl('', [ Validators.required, Validators.minLength(5) ]),
    middlename: new FormControl('', [ Validators.required, Validators.minLength(5) ]),
    mobile: new FormControl('', [ Validators.required, Validators.minLength(5) ]),
    sex: new FormControl('', [ Validators.required, Validators.minLength(3) ]),
    status: new FormControl('activated', [ Validators.required, Validators.minLength(3) ]),
    address: new FormControl('https://www.cobdoglaps.sa.edu.au/wp-content/uploads/2017/11/placeholder-profile-300x187.jpg', [ Validators.required, Validators.minLength(3) ]),
    image: new FormControl('activated', [ Validators.required, Validators.minLength(3) ]),
  });
  email: string;
  error: string;
  db: any;
  constructor(
    public api: RequestService, 
    public gVars: GlobalsService, 
    public location: Location,
    public router: Router
  ) { 
    this.gVars.loginUI();
    this.db = new DbManager(this.api, this.gVars, this.location);
  }

  getAuthData = () => {
    this.gVars.preloader('show');
    this.api.getRequest("accounts/email/"+this.registerForm.controls.email.value)
    .then((res: any) => {
      this.gVars.preloader('hide');
        if(res.response == "No content"){
          this.email = "No content";
        }
        if(typeof res.response == 'object'){
          this.email = "Credentials Found";
        }
    })
    .catch((err: any) => {
      this.gVars.preloader('hide');
      this.gVars.bNotify("Error", "Error", err);
    });
  }

  registerFunction = (registerData: any) => {
    let formData = new FormData();
    for(let key in registerData) {
      formData.append(key, registerData[key]);
    }

    this.gVars.preloader('show');
    this.api.postRequest("accounts", formData)
    .then((res: any) => {
      this.gVars.preloader('hide');
      if(res.message != "OK" || res.response != "inserted" ){
          this.gVars.bNotify("Error", "Request not completed", res.response);
      }
      if(res.message == "OK" && res.response == "inserted"){
        this.gVars.bNotify("Success", "Added ", res.response);
        this.router.navigateByUrl("/login");
      }
    })
    .catch((err: any) => {
      this.gVars.preloader('hide');
      this.gVars.bNotify("Error", "Error", err);
    });
  }
}

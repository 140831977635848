import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators} from '@angular/forms';
import { RequestService } from '../../../services/request-service';
import { GlobalsService } from '../../../services/globals.service';
import { DbManager } from 'src/app/classes/dbm';
import { Location } from '@angular/common';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {

  projectsForm: FormGroup = new FormGroup({
    name: new FormControl('', [ Validators.required, Validators.minLength(5) ]),
    type: new FormControl('', [ Validators.required, Validators.minLength(5) ]),
    scope: new FormControl('', [ Validators.required, Validators.minLength(5) ]),
    requirements: new FormControl('', [ Validators.required, Validators.minLength(5) ]),
    description: new FormControl('', [ Validators.required, Validators.minLength(5) ]),
    startdate: new FormControl('', [ Validators.required, Validators.minLength(5) ]),
    enddate: new FormControl('', [ Validators.required, Validators.minLength(5) ]),
    milestoneachieved: new FormControl('', [ Validators.required, Validators.minLength(5) ]),
    nextactionplan: new FormControl('Project Initialization', [ Validators.required, Validators.minLength(5) ]),
    owner: new FormControl(JSON.parse(sessionStorage.getItem("userdata"))['username'], [ Validators.required, Validators.minLength(5) ]),
  });
  db: any;
  constructor(
    api: RequestService, 
    gVars: GlobalsService, 
    location: Location
  ) {
    this.db = new DbManager(api, gVars, location);
    console.log(this.projectsForm.value);
  }

  ngOnInit() {
    // this.db.readAllData(
    //   "projects/owner/" + this.username, 
    //   (resp) => {
    //     this.projects = resp;
    // })
  }

  addProject = (form: any) => {
    form.name = form.name + " (" +form.type + ")";
    form.scope = form.requirements;
    delete form.type;
    let formData = new FormData();
    for(let key in form) {
      formData.append(key, form[key]);
    }
    this.db.createData("projects", formData, (resp) => {
      console.log(resp);
      
    });


    
    
  }

}

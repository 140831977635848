import { Location } from '@angular/common';
import { RequestService } from '../services/request-service';
import { GlobalsService } from '../services/globals.service';

export class DbManager 
{
    dbData: any;
    constructor(
        public api: RequestService, 
        public gVars: GlobalsService, 
        public location: Location
    ) {
      this.gVars.previewImg = null;
    }

    createData = (route: string, formData: FormData, callback: (res: any) => {}) => {
        this.gVars.preloader('show');
        this.api.postRequest(route, formData)
        .then((res: any) => {
          this.dbData = res.response;
          this.gVars.preloader('hide');
          if(res.message != "OK" || res.response != "inserted" && !res.response.jwt){
              this.gVars.bNotify("Error", "Request not completed", res.response);
          }
          if(res.message == "OK" && res.response == "inserted" || !res.response.jwt || !res.response.data){
            this.gVars.bNotify("Success", "Added ", res.response);
          }
          if(res.message == "OK" && res.response.jwt && res.response.data){
          }
          callback(res);
        })
        .catch((err: any) => {
          this.gVars.preloader('hide');
          this.gVars.bNotify("Error", "Error", err);
        });
    }

    readAllData = (route: string, callback: (response: any) => {}) => {
        this.gVars.preloader('show');
        this.api.getRequest(route)
        .then((res: any) => {
            this.gVars.preloader('hide');
            if(res.message != "OK"){
                this.gVars.bNotify("Error", "Request not completed", (res.response) ? res.response : res.error);
            }
            callback(res.response);
        })
        .catch((err: any) => {
          this.gVars.preloader('hide');
          this.gVars.bNotify("Error", "Error", err);
        });
    }

    deleteData = (route: string, id: string, callback: (response: any) => {}):any => {
        this.gVars.preloader('show');
        this.api.deleteRequest(route + id)
        .then((res: any) => {
          this.dbData = res.response;
          this.gVars.preloader('hide');
          if(res.message != "OK"){
            this.gVars.bNotify("Error", "Request not completed", res.response);
          }
          if(res.message == "OK"){
            this.gVars.bNotify("Success", "Deleted ", res.response);
          }
          callback(res.response);
        })
        .catch((err: any) => {
          this.gVars.preloader('hide');
          this.gVars.bNotify("Error", "Error", err);
        });
    }

    updateData = (route: string, formData: object, callback: (res: any) => {}) => {
        this.gVars.preloader('show');
        this.api.updateRequest(route, formData)
        .then((res: any) => {
          this.dbData = res.response;
          this.gVars.preloader('hide');
          if(res.message != "OK" || res.response != "Updated"){
              this.gVars.bNotify("Error", "Request not completed", res.response);
          }
          if(res.message == "OK" && res.response == "Updated"){
            this.gVars.bNotify("Success", "Request data has been", res.response);
          }
          callback(res.response);
        })
        .catch((err: any) => {
          this.gVars.preloader('hide');
          this.gVars.bNotify("Error", "Error", err);
        });
    }
}
